<template>
  <MasterDetail
    :actionBarButtons="actionBarButtons"
    :cols="columns"
    descriptionProperty="descricao"
    :formTitle="formTitle"
    :opts="opts"
    resourceListProperty="fopag"
    :resourceUrl="resourceUrl"
    @onOpenFormDialog="onOpenFormDialog"
  ></MasterDetail>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapGetters } from 'vuex';
import MasterDetail from "@/components/master-detail.vue";
import { TipoImportacaoEnum } from "@/core/enums/importacoes";

export default {
  components: {
    MasterDetail,
  },
  computed: {
    ...mapGetters(['clientId']),
    actionBarButtons: function () {
      const buttons = [
        {
          text: "Ver FOPAGs",
          icon: "mdi-file-eye",
          show: this.userHasAccess("Colaboradores.folhaDePagamento.index"),
          action: () => {
            this.$router.push({ name: "folha-pagamento-colaborador", params: { colaboradorId: this.colaboradorId }});
          },
        },
         {
          text: "Alocação de Horas",
          icon: "mdi-clock-time-eight-outline",
          show: this.userHasAccess("Timesheet.alocacaoDeHoras.index"),
          action: () => {
            this.$router.push({ name: "timesheets", query: { funcionarioId: this.colaboradorId }});
          },
        },
        {
          text: "Cálculo do Dispêndio",
          icon: "mdi-calculator-variant-outline",
          show: this.userHasAccess("Dispendios.recursosHumanos.index"),
          action: () => {
            this.$router.push({ name: "dispendio-rh", query: { colaboradorId: this.colaboradorId }});
          },
        },
        {
          text: "Importar FOPAG",
          icon: "mdi-import",
          show: this.userHasAccess("Importacoes.recursosHumanos.folhaDePagamento"),
          action: () => {
            this.$router.push({ name: "listagem-importacoes-fopag", query: { tipo: TipoImportacaoEnum.RH_FOPAG }});
          },
        },
        {
          text: "Importar Provisões",
          icon: "mdi-import",
          show: this.userHasAccess("Importacoes.recursosHumanos.provisoes"),
          action: () => {
            this.$router.push({ name: "listagem-importacoes-provisoes", query: { tipo: TipoImportacaoEnum.RH_PROVISOES }});
          },
        },
        {
          text: "Importar Controle Ponto",
          icon: "mdi-import",
          show: this.userHasAccess("Importacoes.recursosHumanos.controleDePonto"),
          action: () => {
            this.$router.push({ name: "listagem-importacoes-ponto", query: { tipo: TipoImportacaoEnum.RH_PONTO }});
          },
        },
      ];

      return buttons.filter(({ show }) => show);
    },
    colaboradorId: function () {
      return this.$route.params.colaboradorId;
    },
    columns: function () {
      return [
        { key: "id", name: "ID Rubrica", align: 1, hideInform: true },
        {
          key: "fopagId",
          name: "FOPAG",
          align: 1,
          type: this.$fieldTypes.TEXT,
          hideInform: true,
        },
        {
          key: "fopagId",
          name: "Rubrica Cadastrada",
          placeholder: `Selecione uma rubrica existente${ this.isCreateModal ? ' ou a opção "Criar nova rubrica"' : '' }`,
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { toEdit: this.isCreateModal ? this.rubricasComCriarNovaRubrica : this.rubricasCadastradas, key: "id", name: "label" },
          hideInTable: true,
          valueChanged: (fopagId) => {
            const { codigo, descricao } = this.rubricasCadastradas.find(({ id }) => id === fopagId) || {};
            this.selectedItem.codigo = codigo;
            this.selectedItem.descricao = descricao;
          },
        },
        {
          key: "codigo",
          name: "Código",
          align: 1,
          type: this.$fieldTypes.TEXT,
          editable: this.selectedItem?.fopagId === -1,
          colSize: 4,
        },
        {
          key: "descricao",
          name: "Descrição",
          type: this.$fieldTypes.TEXT,
          editable: this.selectedItem?.fopagId === -1,
          colSize: 8,
        },
        {
          key: "referencia",
          name: "Referência",
          align: 1,
          type: this.$fieldTypes.NUMBER,
          colSize: 6,
        },
        {
          key: "valor",
          name: "Valor",
          align: 1,
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          colSize: 6,
        },
        {
          key: "template",
          name: "Descrição Sistema",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "template", key: "id", name: "nome" },
          hideInform: true,
        },
        {
          key: "jobId",
          name: "ID Importação",
          editable: false,
          hideInform: true,
          type: this.$fieldTypes.TEXT,
        },
      ];
    },
    formTitle: function () {
      return `${this.isCreateModal ? 'Cadastro' : 'Edição'} de Rubrica`;
    },
    fopagId: function () {
      return this.$route.params.fopagId;
    },
    resourceUrl: function () {
      return `/v1/rh/${this.clientId}/colaboradores/${this.colaboradorId}/fopag/${this.fopagId}/itens`;
    },
  },
  created: function () {
    if (!this.clientId) {
      this.showAlert('Erro ao selecionar empresa', 'Não foi possível recuperar os dados da empresa, por favor selecione uma empresa antes de tentar acessar as Rubricas da FoPag.');
      this.$router.push({ name: "listagem-colaboradores" });
    }
  },
  data: function () {
    return {
      selectedItem: null,
      isCreateModal: false,
      rubricasCadastradas: [],
      rubricasComCriarNovaRubrica: [],
      opts: {
        template: { resourceProperty: "template" },
      },
    };
  },
  methods: {
    getRubricasNaoPareadas: function () {
      this.rubricasCadastradas = [];
      this.rubricasComCriarNovaRubrica = [];
      this.apiResource(`v1/rh/clientes/${this.clientId}/templateCatalogo`)
        .get()
        .then((response) => {
          this.rubricasCadastradas = response
            .map(({ codigo, descricao, id }) => ({ id, label: `${codigo || 'SEM CODIGO'} — ${descricao || 'SEM DESCRIÇÃO'}`, codigo, descricao }))
            .sort(({ label: labelA }, { label: labelB }) => labelA.localeCompare(labelB));
          this.rubricasComCriarNovaRubrica = [{ id: -1, label: 'Criar nova rubrica' }].concat(this.rubricasCadastradas);
        });
    },
    onOpenFormDialog: function (selectedItem) {
      this.isCreateModal = isEmpty(selectedItem);
      this.selectedItem = selectedItem;
      this.getRubricasNaoPareadas();
    },
  },
  watch: {
    clientId: function () {
      this.$router.push({ name: "listagem-colaboradores" });
    },
  },
};
</script>
